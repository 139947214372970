/*
 * @Date: 2023-02-12 13:09:13
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-12 14:37:29
 * @FilePath: \J1-M2-QRP-CODE\actions\API.js
 */
import HostConfig from '$CONFIG/Host.config'
export const CMSURL = HostConfig.Config.CMSURL;
export const HostApi = HostConfig.Config.HostApi;
export const QRCodeURl = HostConfig.Config.QRCODEURL ;

export const ApiPort = {
	// banner 图
	Banner1 : CMSURL + `/cms/qrp-m2-001-banners`,

	// 注册API
	Register: HostApi + `/api/QRP/Register`,
	// 登录
	Login : HostApi + `/api/QRP/Login` ,
	// 
	QrpLoad : HostApi + '/api/QRP/URLs' ,

	// APP 下载 二维码
	Qrcode : QRCodeURl + '/thirdparty-payment/api/v1.0/qr/qr-code',

	AffiliateLink : HostApi + `/api/QRP/AffiliateLink` ,
}